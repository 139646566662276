<template>
  <div
    v-if="itemList.length"
    class="accordion"
    :class="themeColorClass"
  >
    <UiHeader
      v-if="header"
      class="accordion__heading"
      :header="header"
      :subheader="subheader"
      :header-layout="headerLayout"
      :header-position="headerPosition"
      :header-size="headerSize"
      :theme-color="themeColor"
    />

    <AccordionItem
      v-for="item in itemList"
      :key="item.id"
      :item="item"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { defineQuestion, useSchemaOrg } from '#imports'

import { UiHeader } from '~ui/components'
import useTheming from '~ui/composables/useTheming'
import type { UiCeProductBoxesProps } from '~ui/types'

import AccordionItem from './AccordionItem.vue'

const props = defineProps<UiCeProductBoxesProps>()

const themeColorClass = useTheming(props.themeColor)

const questionSchema = computed(() => {
  return props.itemList.filter((item) => item.name && item.text)
})

useSchemaOrg([
  ...questionSchema.value.map((question) =>
    defineQuestion({ name: question.name, acceptedAnswer: question.text })
  )
])
</script>

<style lang="scss">
.accordion {
  padding-bottom: rem(20px);

  &__heading {
    margin-bottom: rem(35px);
  }
}
</style>
